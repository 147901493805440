"use client";
import React, { useRef, useEffect, useState } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import CustomImage from "../CustomImage";
import { useRouter } from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import SwiperCore from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
interface PromotionsCardProps {
  categories: any[];
}

const PromotionCategoriesCard: React.FC<PromotionsCardProps> = ({
  categories,
}: any) => {
  const swiperRef = useRef<SwiperCore | null>(null);

  const p = "/images/indexPage";
  const categoryCards = [
    {
      imageLink: `${p}/fashion.png`,
      name: "Fashion",
      href: "/",
    },
    {
      imageLink: `${p}/gaming.png`,
      name: "Gaming",
      href: "/",
    },
    {
      imageLink: `${p}/stayConnected.png`,
      name: "Stay Connected",
      href: "/",
    },
    {
      imageLink: `${p}/food.png`,
      name: "Food",
      href: "/",
    },
    {
      imageLink: `${p}/travel.png`,
      name: "Travel",
      href: "/",
    },
    {
      imageLink: `${p}/entertainment.png`,
      name: "Entertainment",
      href: "/",
    },
    {
      imageLink: `${p}/retails.png`,
      name: "Retails",
      href: "/",
    },
    {
      imageLink: `${p}/globalConnectivity.png`,
      name: "Global Connectivity",
      href: "/",
    },
  ];

  const [cards, setCards] = useState(categoryCards);

  const categoryMappings = {
    Fashion: "Gift Cards",
    "Stay Connected": "Mobile Top-UP",
    Food: "Gift Cards",
    Travel: "Gift Cards",
    Entertainment: "Pay Bills",
    Retails: "Gift Cards",
    "Global Connectivity": "Mobile Top-UP",
    Gaming: "Pay Bills",
  };
  const subCategoryMappings = {
    Fashion: "fashion", // Gift Cards
    "Stay Connected": "airtime", // Mobile Top-UP
    Food: "food-and-restaurants", // Gift Cards
    Travel: "transportation", // Gift Cards
    Entertainment: "cable-tv", // Pay Bills
    Retails: "retail", // Gift Cards
    "Global Connectivity": "data", // Mobile Top-UP
    Gaming: "betting", // Pay Bills
  };

  function getCategoryHref(categoryName: string) {
    //@ts-expect-error error expected
    const specificCategory = categoryMappings[categoryName];
    const categoryObj = categories.data.find(
      (cat: any) =>
        cat.category.toLowerCase() === specificCategory.toLowerCase()
    );
    if (!categoryObj) return "/";

    //@ts-expect-error error expected
    const subCategorySlug = subCategoryMappings[categoryName];
    const subCategory = categoryObj.subCategories.find(
      (subCat: { slug: any }) =>
        subCat.slug.toLowerCase() === subCategorySlug.toLowerCase()
    );

    if (subCategory) {
      return `/categories/${categoryObj.id}/${subCategory.id}`;
    }

    return "/";
  }

  useEffect(() => {
    if (categories) {
      const posts = categoryCards.map((card) => {
        const href = getCategoryHref(card.name);
        if (href) {
          card.href = href;
        }
        return card;
      });
      setCards(posts);
    }
  }, [categories]);

  return (
    <div className="productContainer categoresCardList">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Left Button */}
        <button
          className="btn leftBtn d-none d-lg-block"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <MdKeyboardArrowLeft />
        </button>

        {/* Product List */}
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={20}
          loop={true}
          autoplay={{ disableOnInteraction: false, pauseOnMouseEnter: true }}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          breakpoints={{
            320: {
              slidesPerView: 1,
              centeredSlides: true,
              centerInsufficientSlides: true,
            }, // Mobile
            768: {
              slidesPerView: 1,
              centeredSlides: true,
              centerInsufficientSlides: true,
            }, // Tablet
            1024: {
              slidesPerView: 3,
              spaceBetween: 100,
              centerInsufficientSlides: true,
            }, // Laptop & larger
          }}
        >
          {/* @ts-ignore data exists in products */}
          {cards?.map((item: any, key: number) => (
            <SwiperSlide key={key}>
              <div
                className="zoom py-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link href={item.href}>
                  <Image
                    src={item.imageLink}
                    alt={`${item.name}`}
                    width={100}
                    height={100}
                    layout="responsive"
                    draggable="false"
                    loading={"lazy"}
                    className="homeSectionImage link"
                    decoding={"async"}
                    objectFit="cover"
                  />
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Right Button */}
        <button
          className="btn rightBtn d-none d-lg-block"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <MdKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};

export default PromotionCategoriesCard;
