"use client";
import React, { useRef, useEffect } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import CustomImage from "../CustomImage";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import SwiperCore from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
interface ImageItem {
  imageLink: string;
}

interface ContentItem {
  name: string;
  imageUrl: string;
}

interface SupportedBlockchainsProps {
  list: ImageItem[];
  content: ContentItem[];
}

const SupportedBlockchains: React.FC<SupportedBlockchainsProps> = ({
  list,
  content,
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);

  return (
    <div className="productContainer SupportedBlockchains">
      <h1 className="font-weight-bold text-center">Supported Blockchains</h1>
      <p className="text-center mb-4">
        Make payments using multiple tokens across different blockchains
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          className="btn leftBtn d-none d-lg-block"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <MdKeyboardArrowLeft />
        </button>
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={20}
          loop={true}
          autoplay={{ disableOnInteraction: false, pauseOnMouseEnter: true }}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          breakpoints={{
            320: { slidesPerView: 3 }, // Mobile
            768: { slidesPerView: 5 }, // Tablet
            1024: { slidesPerView: 9 }, // Laptop & larger
          }}
        >
          {" "}
          {content?.map((item, key) => (
            <SwiperSlide key={key}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  columnGap: 5,
                  alignItems: "center",
                }}
              >
                <CustomImage fullSrc={item.imageUrl} height={100} />
                <p className="text-center">{item.name}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          className="btn rightBtn d-none d-lg-block"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <MdKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};

export default SupportedBlockchains;
