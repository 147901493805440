"use client";
import React, { useRef } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import CustomImage from "../CustomImage";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import SwiperCore from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

interface ImageItem {
  imageLink: string;
}

interface ProductsCardProps {
  imageList: ImageItem[];
  products: Array<any>;
  location: string;
  slug: string;
  url?: string;
}

const ProductsCard: React.FC<ProductsCardProps> = ({
  imageList,
  products,
  location,
  slug,
  url,
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);

  return (
    <div className="productContainer px-0">
      <div className="header d-flex justify-content-between align-items-center">
        <h4>
          {slug} Products in <span className="location_text">{location}</span>
        </h4>
        <Link
          href={`/categories?type=${url}`}
          className="btn btn-primary shadow-sm px-4"
        >
          View All
        </Link>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* Left Button */}
        <button
          className="btn leftBtn d-none d-lg-block"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <MdKeyboardArrowLeft />
        </button>

        {/* Product List */}
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={20}
          loop={true}
          autoplay={{ disableOnInteraction: false, pauseOnMouseEnter: true }}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          breakpoints={{
            320: { slidesPerView: 2 }, // Mobile
            768: { slidesPerView: 3 }, // Tablet
            1024: { slidesPerView: 4 }, // Laptop & larger
          }}
        >
          {/* @ts-ignore data exists in products */}
          {products?.data?.items?.map((item: any, key: number) => (
            <SwiperSlide key={key}>
              <div
                className="zoom link"
                style={{
                  flex: "0 0 auto",
                  boxShadow:
                    "0px 2px 4px rgba(0, 0, 0, 0.1), 0px -4px 4px rgba(0, 0, 0, 0.1), 2px 0px 4px rgba(0, 0, 0, 0.1), -2px 0px 4px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  overflow: "hidden",
                  marginTop: 12,
                  marginBottom: 12,
                }}
              >
                <Link
                  href={`/products/${item?.id}`}
                  style={{ textDecoration: "none", position: "relative" }}
                >
                  <CustomImage
                    fullSrc={
                      item?.imageUrl ||
                      imageList[key]?.imageLink ||
                      "indexPage/debitCard.svg"
                    }
                    className={`productImage ${
                      item.isOutOfStock ? "outOfStockCard_image" : ""
                    }`}
                  />
                  {item.isOutOfStock && (
                    <div className="outOfStockBannerCategories">
                      Out of Stock
                    </div>
                  )}
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Right Button */}
        <button
          className="btn rightBtn d-none d-lg-block"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <MdKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};

export default ProductsCard;
